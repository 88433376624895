import { lazy } from "react";

// project imports
import UserInfoLayout from "layout/UserInfoLayout";
import Loadable from "ui-component/Loadable";

const PersonalInfo = Loadable(lazy(() => import("views/user/personal-info")));
const PaymentMethod = Loadable(lazy(() => import("views/user/payment-method")));

const UserInfoRoutes = {
  path: "/",
  element: <UserInfoLayout />,
  children: [
    {
      path: "/personal-info",
      element: <PersonalInfo />,
    },
    {
      path: "/payment-method",
      element: <PaymentMethod />,
    },
  ],
};

export default UserInfoRoutes;
