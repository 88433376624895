import { Box, CssBaseline, styled, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

const Main = styled("main")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  flexGrow: 1,
  overflow: "hidden",
}));

const AuthLayout = () => {
  const theme = useTheme();

  return (
    <Box>
      <CssBaseline />
      <Main theme={theme}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default AuthLayout;
