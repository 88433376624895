import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

const StyledLogo = styled(Typography)(({ theme }) => ({
  fontFamily: "Philosopher",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "26.88px",
  color: theme.palette.common.white,
  textDecoration: "none",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
}));

const LightLogo = () => {
  return (
    <StyledLogo component={StyledLink} to="/">
      Tête à tête
    </StyledLogo>
  );
};

export default LightLogo;
