import { createTheme } from "@mui/material/styles";

const Palette = () => {
  return createTheme({
    palette: {
      background: {
        default: "#F8F7F3",
      },
      common: {
        black: "#000000",
        white: "#FFFFFF",
      },
      text: {
        primary: "#111111",
        secondary: "#555555",
        tertiary: "#CAC9C9",
        light: "#DD9DB1",
        hint: "#9e9e9e",
        footerText: "#777777",
      },
      custom: {
        mainColor: "#F8F7F3",
        darkColor: "#111111",
        lightColor: "#FFD979",
        borderColor: "#CAC9C9",
        grayColor: "#BEBEBE",
        tabColor: "#989191",
      },
    },
  });
};

export default Palette;
