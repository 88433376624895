import {
  Box,
  CssBaseline,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Header from "layout/MainLayout/header";
import Footer from "layout/MainLayout/footer";
import Sidebar from "./Sidebar";
import SectionBox from "ui-component/box/SectionBox";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  overflow: "auto",
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 0),
  },
}));

const UserInfoLayout = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Header />
      <Main theme={theme}>
        <SectionBox>
          <Grid container spacing={5}>
            {!isSmallScreen && (
              <Grid item xs={12} sm={4} md={3}>
                <Sidebar />
              </Grid>
            )}
            <Grid item xs={12} sm={8} md={9} px={2}>
              <Outlet />
            </Grid>
          </Grid>
        </SectionBox>
      </Main>
      <Footer />
    </Box>
  );
};

export default UserInfoLayout;
