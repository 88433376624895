import { lazy } from "react";

// project imports
import AuthLayout from "layout/AuthLayout";
import Loadable from "ui-component/Loadable";

const Login = Loadable(lazy(() => import("views/auth/layouts/login")));
const Register = Loadable(lazy(() => import("views/auth/layouts/register")));
const Verification = Loadable(
  lazy(() => import("views/auth/layouts/verification"))
);

const AuthRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/verification",
      element: <Verification />,
    },
  ],
};

export default AuthRoutes;
