import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Drawer,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as AccountCardIcon } from "assets/svg/profile/account-credit.svg";
import { ReactComponent as ContactUsIcon } from "assets/svg/profile/contact-us.svg";
import { ReactComponent as FeedbackIcon } from "assets/svg/profile/feedback.svg";
import { ReactComponent as GiftCardIcon } from "assets/svg/profile/gift-card.svg";
import GiftImg from "assets/svg/profile/gift.png";
import { ReactComponent as LogoutIcon } from "assets/svg/profile/logout.svg";
import { ReactComponent as PaymentIcon } from "assets/svg/profile/payment-method.svg";
import { ReactComponent as FolderIcon } from "assets/svg/profile/personal-info.svg";
import { ReactComponent as TermsIcon } from "assets/svg/profile/terms-of-service.svg";
import { ReactComponent as TrashIcon } from "assets/svg/profile/trash.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DarkButton from "ui-component/button/DarkButton";

const ListBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  border: "1px solid #E8E7E3",
  borderRadius: "12px",
}));

const ListItemTextStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  ...theme.typography.f13,
  fontWeight: 700,
  color: active
    ? theme.palette.custom.darkColor
    : theme.palette.custom.tabColor,
}));

const FolderIconStyled = styled(FolderIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const AccountCardIconStyled = styled(AccountCardIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const ContactUsIconStyled = styled(ContactUsIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const FeedbackIconStyled = styled(FeedbackIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const GiftCardIconStyled = styled(GiftCardIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const LogoutIconStyled = styled(LogoutIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const PaymentIconStyled = styled(PaymentIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const TermsIconStyled = styled(TermsIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const TrashIconStyled = styled(TrashIcon, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  "& path": {
    stroke: active ? theme.palette.custom.lightColor : "",
  },
}));

const SidebarItem = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(2),
  cursor: "pointer",
  borderBottom: "1px solid #E8E7E3",
  borderRadius: "0",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),

  "&:last-child": {
    borderBottom: "none",
    borderRadius: "inherit",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
  },

  "&:first-child": {
    borderRadius: "inherit",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
  },

  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
}));

const EarnCard = styled(Box)(({ theme }) => ({
  borderRadius: "20px",
  background: theme.palette.custom.lightColor,
  padding: theme.spacing(2),
  position: "relative",
  marginTop: theme.spacing(3),
}));

const GiftImgWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: -20,

  [theme.breakpoints.down("md")]: {
    right: -8,
    top: 0,
  },
}));

const GiftImgBox = styled(Box)(({ theme }) => ({
  width: "130px",
  height: "130px",

  [theme.breakpoints.down("lg")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100px",
    height: "100px",
  },
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "280px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    padding: theme.spacing(2),
  },
}));

const Sidebar = ({
  sidebarDrawerOpen,
  toggleSidebarDrawer,
  setSidebarDrawerOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNavigation = (path) => {
    if (path === "/login") {
      localStorage.removeItem("token");
    }
    navigate(path);
    if (isSmallScreen) {
      setSidebarDrawerOpen(false);
    }
  };

  const menuItems = [
    {
      path: "/personal-info",
      label: "Personal information",
      icon: (active) => <FolderIconStyled active={active} />,
    },
    {
      path: "/payment-method",
      label: "Payment method",
      icon: (active) => <PaymentIconStyled active={active} />,
    },
    {
      path: "/account-credit",
      label: "Account Credit",
      icon: (active) => <AccountCardIconStyled active={active} />,
    },
    {
      path: "/gift-cards",
      label: "Gift Cards",
      icon: (active) => <GiftCardIconStyled active={active} />,
    },
    {
      path: "/contact-us",
      label: "Contact Us",
      icon: (active) => <ContactUsIconStyled active={active} />,
    },
    {
      path: "/feedback",
      label: "Give us feedback",
      icon: (active) => <FeedbackIconStyled active={active} />,
    },
    {
      path: "/terms-of-service",
      label: "Terms of Service",
      icon: (active) => <TermsIconStyled active={active} />,
    },
    {
      path: "/login",
      label: "Log out",
      icon: (active) => <LogoutIconStyled active={active} />,
    },
    {
      path: "/delete-account",
      label: "Delete account",
      icon: (active) => <TrashIconStyled active={active} />,
    },
  ];

  const menuContent = (
    <Box>
      <Stack direction={"column"} gap={2.5}>
        <ListBox>
          {menuItems.slice(0, 4).map((item, index) => (
            <SidebarItem
              onClick={() => handleNavigation(item.path)}
              active={location.pathname === item.path}
              key={index}
            >
              {item.icon(location.pathname === item.path)}
              <ListItemTextStyled active={location.pathname === item.path}>
                {item.label}
              </ListItemTextStyled>
            </SidebarItem>
          ))}
        </ListBox>

        <ListBox>
          {menuItems.slice(4, 7).map((item, index) => (
            <SidebarItem
              onClick={() => handleNavigation(item.path)}
              active={location.pathname === item.path}
              key={index}
            >
              {item.icon(location.pathname === item.path)}
              <ListItemTextStyled active={location.pathname === item.path}>
                {item.label}
              </ListItemTextStyled>
            </SidebarItem>
          ))}
        </ListBox>

        <ListBox>
          {menuItems.slice(7, 9).map((item, index) => (
            <SidebarItem
              onClick={() => handleNavigation(item.path)}
              active={location.pathname === item.path}
              key={index}
            >
              {item.icon(location.pathname === item.path)}
              <ListItemTextStyled active={location.pathname === item.path}>
                {item.label}
              </ListItemTextStyled>
            </SidebarItem>
          ))}
        </ListBox>

        <Box>
          <Typography variant="f13" fontWeight={400} color={"text.secondary"}>
            In navigating the complex corporate governance landscape, Neil
            stands out not just as a mentor but as a visionary connector.
          </Typography>
        </Box>

        <DarkButton title="Become an Expert" fullWidth />

        <EarnCard>
          <Stack direction={"column"}>
            <Typography
              sx={{ lineHeight: "30px" }}
              variant="f34"
              fontWeight={700}
            >
              Earn $10
            </Typography>
            <Typography variant="f12" fontWeight={400}>
              For each $10
            </Typography>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography variant="f15" fontWeight={700}>
              Invite friends
            </Typography>
            <ChevronRightIcon />
          </Stack>
          <GiftImgWrapper>
            <GiftImgBox component={"img"} src={GiftImg} />
          </GiftImgWrapper>
        </EarnCard>
      </Stack>
    </Box>
  );

  return (
    <Box>
      {isSmallScreen ? (
        <>
          <CustomDrawer
            anchor="right"
            open={sidebarDrawerOpen}
            onClose={() => toggleSidebarDrawer(false)}
          >
            {menuContent}
          </CustomDrawer>
        </>
      ) : (
        menuContent
      )}
    </Box>
  );
};

export default Sidebar;
