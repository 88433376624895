export default function componentStyleOverrides(theme, borderRadius) {
  const menuSelectedBack = theme.palette.secondary.light;
  const menuSelected = theme.palette.secondary.dark;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          ...theme.typography.f14,
          borderRadius: "20px",
          fontWeight: "700",
          padding: theme.spacing(2.5, 3.5),
          color: theme.palette.text.primary,
          textTransform: "capitalize",

          [theme.breakpoints.down("md")]: {
            ...theme.typography.f12,
            fontWeight: "700",
            padding: theme.spacing(2),
          },
          "& .MuiButton-startIcon": {
            marginRight: theme.spacing(2),
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {},
        colorSecondary: {
          background: theme.palette.secondary.main,
          "&:hover": {
            background: theme.palette.secondary.dark,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px dashed",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            "&:hover": {
              backgroundColor: menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: "12px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.position === "start" && {
            marginTop: "0 !important",
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        input: {
          color: theme.palette.text.secondary,
          fontSize: "14px",
          fontWeight: 400,
          "&::placeholder": {
            color: "#B3B3B3",
            fontSize: "14px",
            fontWeight: 400,
          },
          "&:-webkit-autofill": {
            WebkitTextFillColor: theme.palette.text.dark,
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          height: 50,
          // borderRadius: "20px",
          // marginTop: theme.spacing(1),

          // "& .MuiOutlinedInput-notchedOutline": {
          //   borderColor: theme.palette.grey[400],
          // },
          // "&:hover $notchedOutline": {
          //   borderColor: theme.palette.primary.light,
          // },
          // "&:hover .MuiOutlinedInput-notchedOutline": {
          //   borderColor: theme.palette.secondary.main,
          // },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.text.primary,
            borderWidth: 1,
          },
          "& .MuiOutlinedInput-input:not(:placeholder-shown) + .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #d0d0d0",
            },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        input: {
          fontWeight: 500,
          padding: "0 16px",
          backgroundColor: theme.palette.common.white,
          height: "60px",
          borderRadius: "20px",

          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        multiline: {
          borderRadius: "10px !important",
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: "8px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          fontSize: "14px",
          fontWeight: 400,

          borderRadius: "20px",
          marginTop: theme.spacing(1),
          background: `${theme.palette.common.white} !important`,

          "& .MuiFilledInput-notchedOutline": {
            borderColor: theme.palette.grey[400],
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&:hover .MuiFilledInput-notchedOutline": {
            borderColor: theme.palette.secondary.main,
          },
          "&.Mui-focused .MuiFilledInput-notchedOutline": {
            borderColor: theme.palette.text.primary,
          },
          "& .MuiFilledInput-input:not(:placeholder-shown) + .MuiFilledInput-notchedOutline":
            {
              border: "1px solid #d0d0d0",
            },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },

          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "1px solid transparent",
          },

          "&::before": {
            borderBottom: "1px solid transparent",
          },
          "&::after": {
            borderBottom: "1px solid transparent",
          },
        },

        input: {
          fontWeight: 500,
          padding: "0 16px",
          background: theme.palette.common.white,
          height: "60px",
          borderRadius: "20px",

          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        multiline: {
          borderRadius: "10px !important",
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: "8px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.common.white,
          width: "4px",
        },
        valueLabel: {
          color: theme.palette.primary.light,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-tag": {
            background: theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            ".MuiChip-deleteIcon": {
              color: theme.palette.secondary[200],
            },
          },
          "& .MuiOutlinedInput-root": {
            padding: "5px",
          },
        },
        listbox: {
          height: "255px !important",
          [theme.breakpoints.down("md")]: {
            height: "200px !important",
          },
        },
        option: {
          minHeight: "30px !important",
        },
        paper: {
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "8px",
          backgroundColor: "#f9f9f9",
        },
        popper: {
          borderRadius: `0`,
          marginTop: "10px !important",
          boxShadow:
            "0px 8px 10px -5px rgb(0 0 0 / 1%), 0px 16px 24px 2px rgb(0 0 0 / 1%), 0px 6px 30px 5px rgb(0 0 0 / 1%)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "transparent",

          "&.Mui-checked .MuiSvgIcon-root": {
            background: theme.palette.common.black,
          },

          "& .MuiSvgIcon-root": {
            borderRadius: "50%",
            backgroundColor: "transparent",
            border: "1px solid #E8E7E3",

            "&.Mui-checked": {
              backgroundColor: theme.palette.common.black,
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.dark,
          background: theme.palette.primary[200],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "12px 0 12px 0",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px",
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.grey.textColor,
          borderColor: "#232322",

          "&.MuiTableCell-head": {
            fontSize: "12px",
            fontWeight: 600,
            background: "",
            color: "#232322",
            borderBottom: "1px solid #ccc",
            padding: "8px",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 44,
          height: 24,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 2,
            background: "transparent",
            color: "#D7D7D7",
            "&:hover": {
              background: "transparent",
            },
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: theme.palette.common.black,
              "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.common.white,
                opacity: 1,
                border: "none",
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.common.white,
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // borderRadius: 8,
          padding: "8px 16px",
          "&.Mui-disabled": {
            background: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
          },

          "&.MuiTabScrollButton-root": {
            display: "flex",
            background: theme.palette.custom.lightColor,
            borderRadius: theme.spacing(0.5),
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          padding: "16px !important",
          flexDirection: "row-reverse",
          "&.Mui-focused": {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: "1.5px solid #DFDFDF",
          width: "100%",

          [theme.breakpoints.down("md")]: {
            width: "max-content",
          },
        },
        indicator: {
          backgroundColor: theme.palette.text.primary,
        },
        scrollButtons: {
          display: "block",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...theme.typography.f14,
          fontWeight: 700,
          textTransform: "capitalize",
          color: theme.palette.custom.tabColor,

          "&.Mui-selected": {
            color: theme.palette.text.primary,
          },
        },
      },
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[400],
        },
      },
    },
  };
}
