import { Navigate, createBrowserRouter } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes";
import UserInfoRoutes from "./UserInfoRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([
  { path: "*", element: <Navigate to="/" /> },
  MainRoutes,
  AuthRoutes,
  UserInfoRoutes,
]);

export default router;
