// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
  registerResponse: {},
  userData: {},
  otpResponse: {},
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    loginSuccess(state, action) {
      state.authData = action.payload;
    },

    registerSuccess(state, action) {
      state.registerResponse = action.payload;
    },

    userDataSuccess(state, action) {
      state.userData = action.payload;
    },

    hasOtpError(state, action) {
      state.error = action.payload;
    },

    sendOtpSuccess(state, action) {
      state.otpResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  hasError,
  loginSuccess,
  registerSuccess,
  userDataSuccess,
  hasOtpError,
  sendOtpSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
export function login(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post("/app/v1/auth/login", payload);
      dispatch(loginSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function register(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post("api/user/create", payload);
      console.log("Registered User Data:", response.data);
      dispatch(registerSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function verifyOTP(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post("api/user/verifyOTP", payload);
      dispatch(userDataSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function sendOTP(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post("api/user/sendOTP", payload);
      dispatch(sendOtpSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasOtpError(error));
      throw error;
    }
  };
}
