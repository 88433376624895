import { Box, CssBaseline, styled, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  overflow: "auto",
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 0),
  },
}));

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Header />
      <Main theme={theme}>
        <Outlet />
      </Main>
      <Footer />
    </Box>
  );
};

export default MainLayout;
