const Typography = (theme) => ({
  fontFamily: `"Merriweather", sans-serif`,

  f80: {
    fontSize: "80px",
    lineHeight: "90px",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
      lineHeight: "1.5",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "46px",
      lineHeight: "1.5",
    },
  },

  f60: {
    fontSize: "60px",
    lineHeight: "60px",
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
      lineHeight: "1.5",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "1.5",
    },
  },

  f62: {
    fontSize: "62px",
    lineHeight: "60px",
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontSize: "40px",
      lineHeight: "1.5",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      lineHeight: "1.5",
    },
  },

  f50: {
    fontSize: "50px",
    lineHeight: "90px",
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontSize: "26px",
      lineHeight: "1.5",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "1.5",
    },
  },

  f34: {
    fontSize: "34px",
    lineHeight: "60px",

    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      lineHeight: "1.5",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "1.5",
    },
  },

  f24: {
    fontSize: "24px",
    lineHeight: "24px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
  },

  f18: {
    fontSize: "18px",
    lineHeight: "20px",
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: "15px",
    //   lineHeight: "17px",
    // },
  },

  f16: {
    fontSize: "16px",
    lineHeight: "30px",
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: "13px",
    //   lineHeight: "26px",
    // },
  },

  f15: {
    fontSize: "15px",
    lineHeight: "22px",
    [theme.breakpoints.down("lg")]: {},
  },

  f14: {
    fontSize: "14px",
    lineHeight: "20px",
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: "12px",
    //   lineHeight: "20px",
    // },
  },

  f13: {
    fontSize: "13px",
    lineHeight: "26px",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "11px",
    //   lineHeight: 1.5,
    // },
  },

  f12: {
    fontSize: "12px",
    lineHeight: "30px",
    [theme.breakpoints.down("lg")]: {},
  },
});

export default Typography;
