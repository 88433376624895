// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  userList: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    userListSuccess(state, action) {
      state.userList = action.payload.data;
    },
  },
});

// Reducer
export default slice.reducer;
export const { hasError, userListSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function getUserList() {
  return async (dispatch) => {
    try {
      const response = await axios.get("api/user/get");
      dispatch(userListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getUserByCategory(categoryId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `api/user/getByCategoryId/${categoryId}`
      );
      dispatch(userListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
