import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  isMobile: false,
};

// ==============================|| SLICE - COMMON ||============================== //

const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    checkIsMobile(state, action) {
      state.isMobile = action.payload;
    },
  },
});

export default common.reducer;

export const { checkIsMobile } = common.actions;
