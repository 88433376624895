import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpertPlaceHolderImg from "assets/images/expert-placeholder.jpg";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Backdrop,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import MyProfileImg from "assets/images/top-expert.png";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LightButton from "ui-component/button/LightButton";

import { ReactComponent as GiftSvg } from "assets/svg/gift.svg";
import { ReactComponent as LockSvg } from "assets/svg/lock.svg";
import { useSelector } from "store";
import SectionBox from "ui-component/box/SectionBox";
import DarkLogo from "ui-component/DarkLogo";
import SearchSection from "./Search";
import Sidebar from "layout/UserInfoLayout/Sidebar";

const StyledDiv = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2, 0),
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 0),
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(5),
  padding: 0,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "auto",
  padding: theme.spacing(0),
  "&:hover": {
    backgroundColor: theme.palette.custom.lightColor,
    borderRadius: theme.spacing(1),
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  fontSize: "14px !important",
  fontWeight: "400 !important",
  lineHeight: "20px !important",
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  display: "block",
  width: "100%",
  display: "flex",
  alignItems: "center",
}));

const ActiveLink = styled(StyledLink)(({ theme }) => ({
  backgroundColor: theme.palette.custom.lightColor,
  borderRadius: theme.spacing(1),
}));

const MenuItemLink = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.custom.lightColor,
  },
}));

const ProfileBox = styled(Box)(({ theme }) => ({
  height: 50,
  width: 50,
  borderRadius: "50%",
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  bottom: 0,
  left: 0,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  padding: theme.spacing(2),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  maxHeight: "70vh",
  overflowY: "auto",
}));

const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const LinkComponent = isActive ? ActiveLink : StyledLink;

  return (
    <LinkComponent to={to} onClick={onClick}>
      <ListItemText primary={children} />
    </LinkComponent>
  );
};

const NavList = ({ toggleDrawer }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledListItem
        onMouseEnter={handleMenuOpen}
        onMouseLeave={handleMenuClose}
      >
        <NavLink to="#" onClick={toggleDrawer(false)}>
          <Stack direction={"row"}>
            Categories
            <ArrowDropDownIcon />
          </Stack>
        </NavLink>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{ onMouseLeave: handleMenuClose }}
        >
          <MenuItemLink
            onClick={handleMenuClose}
            component={Link}
            to="/category1"
          >
            Category 1
          </MenuItemLink>
          <MenuItemLink
            onClick={handleMenuClose}
            component={Link}
            to="/category2"
          >
            Category 2
          </MenuItemLink>
          <MenuItemLink
            onClick={handleMenuClose}
            component={Link}
            to="/category3"
          >
            Category 3
          </MenuItemLink>
        </Menu>
      </StyledListItem>
      <StyledListItem>
        <NavLink to="#" onClick={toggleDrawer(false)}>
          Our Mission
        </NavLink>
      </StyledListItem>
      <StyledListItem>
        <NavLink to="#" onClick={toggleDrawer(false)}>
          Become an expert
        </NavLink>
      </StyledListItem>
    </>
  );
};

const GiftButton = () => {
  return (
    <LightButton
      variant="contained"
      color="secondary"
      startIcon={<GiftSvg />}
      title="Gift a Session"
    />
  );
};

const Header = () => {
  const theme = useTheme();
  const { isMobile } = useSelector((state) => state.commonReducer);
  const { userData } = useSelector((state) => state.auth);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sidebarDrawerOpen, setSidebarDrawerOpen] = useState(false);
  const [slideDirection, setSlideDirection] = useState("up");
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const token = localStorage.getItem("token");

  const toggleSidebarDrawer = (open) => {
    setSidebarDrawerOpen(open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open) {
      setSlideDirection("up");
      document.body.style.overflow = "hidden";
    } else {
      setSlideDirection("up");
      document.body.style.overflow = "auto";
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerOpen &&
        !event.target.closest("#drawer-content") &&
        !event.target.closest("#menu-button")
      ) {
        setSlideDirection("down");
        setDrawerOpen(false);
        document.body.style.overflow = "auto";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen]);

  return (
    <StyledDiv>
      <SectionBox>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <DarkLogo />
          <Stack
            direction={"row"}
            justifyContent="flex-end"
            alignItems="center"
            gap={5}
          >
            <StyledList>
              <NavList toggleDrawer={toggleDrawer} />
            </StyledList>

            <SearchSection />

            {isMobile ? "" : <GiftButton />}

            <MenuButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </MenuButton>

            {token ? (
              <Box
                display={"flex"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  isSmallScreen
                    ? toggleSidebarDrawer(!sidebarDrawerOpen)
                    : navigate("/personal-info")
                }
              >
                <ProfileBox
                  component={"img"}
                  src={
                    userData?.data?.image
                      ? userData?.data?.image
                      : ExpertPlaceHolderImg
                  }
                />
              </Box>
            ) : (
              <LightButton
                variant="contained"
                color="secondary"
                startIcon={<LockSvg />}
                title="login"
                onClick={() => navigate("/login")}
              />
            )}
          </Stack>
        </Stack>
      </SectionBox>

      {isSmallScreen && (
        <Sidebar
          sidebarDrawerOpen={sidebarDrawerOpen}
          toggleSidebarDrawer={toggleSidebarDrawer}
          setSidebarDrawerOpen={setSidebarDrawerOpen}
        />
      )}

      <Backdrop
        open={drawerOpen}
        onClick={toggleDrawer(false)}
        sx={{ zIndex: theme.zIndex.drawer }}
      />
      <Slide
        direction={slideDirection}
        in={drawerOpen}
        mountOnEnter
        unmountOnExit
      >
        <DrawerContent id="drawer-content">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" gutterBottom>
              Menu
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <List>
            <NavList toggleDrawer={toggleDrawer} />
          </List>
          <GiftButton />
        </DrawerContent>
      </Slide>
    </StyledDiv>
  );
};

export default Header;
