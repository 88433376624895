import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import AnimateButton from "ui-component/extended/AnimateButton";
import LightLogo from "ui-component/LightLogo";
import { ReactComponent as DiscordSvg } from "assets/svg/footer/discord.svg";
import { ReactComponent as TelegramSvg } from "assets/svg/footer/telegram.svg";
import { ReactComponent as TwitterSvg } from "assets/svg/footer/twitter.svg";

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#121212",
  color: "#FFFFFF",
  padding: theme.spacing(8),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(4),
  },
}));

const FooterSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: {
    marginBottom: 0,
    gap: theme.spacing(1.5),
  },
}));

const EmptyBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const BecomeAnExpertBtn = styled(Button)(({ theme }) => ({
  display: "inline-block",
  border: "1px solid #F8F7F3",
  boxShadow: "0px 10px 40px 0px #5555551F",
  cursor: "pointer",

  background: theme.palette.custom.darkColor,
  color: `${theme.palette.common.white} !important`,

  ":hover": {
    background: theme.palette.custom.darkColor,
    boxShadow: "none",
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  ...theme.typography.f13,
  color: theme.palette.text.footerText,
  textDecoration: "none",
  display: "block",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Footer = () => {
  const theme = useTheme();
  return (
    <FooterContainer>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        flexGrow={1}
        spacing={4}
      >
        <FooterSection>
          <LightLogo />

          <AnimateButton>
            <BecomeAnExpertBtn type="button" variant="contained">
              Become an Expert
            </BecomeAnExpertBtn>
          </AnimateButton>
          <Typography
            variant="f13"
            fontWeight={400}
            color="text.secondary"
            mt={2}
          >
            Copyright
          </Typography>
        </FooterSection>

        <FooterSection>
          <Typography variant="f14" fontWeight={700} gutterBottom>
            Categories
          </Typography>
          <FooterLink href="#">Business & Carrière</FooterLink>
          <FooterLink href="#">Architecture & Design</FooterLink>
          <FooterLink href="#">Mode & Beauté</FooterLink>
          <FooterLink href="#">Santé & Bien-être</FooterLink>
        </FooterSection>

        <FooterSection>
          <EmptyBox />
          <EmptyBox />
          <EmptyBox />
          <FooterLink href="#">Art & Divertissement</FooterLink>
          <FooterLink href="#">Sport & Fitness</FooterLink>
          <FooterLink href="#">Astrologie & autres</FooterLink>
        </FooterSection>

        <FooterSection>
          <Typography variant="f14" fontWeight={700} gutterBottom>
            Support
          </Typography>
          <FooterLink href="#">FAQ</FooterLink>
          <FooterLink href="#">Privacy Policy</FooterLink>
          <FooterLink href="#">Terms & Conditions</FooterLink>
        </FooterSection>

        <FooterSection>
          <Typography variant="f14" fontWeight={700} gutterBottom>
            Follow
          </Typography>

          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <DiscordSvg />
            <FooterLink href="#" display="flex" alignItems="center">
              Discord
            </FooterLink>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <TelegramSvg />
            <FooterLink href="#" display="flex" alignItems="center">
              Telegram
            </FooterLink>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <TwitterSvg />
            <FooterLink href="#" display="flex" alignItems="center">
              Twitter
            </FooterLink>
          </Stack>
        </FooterSection>
      </Stack>
    </FooterContainer>
  );
};

export default Footer;
