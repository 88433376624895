import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const Home = Loadable(lazy(() => import("views/home")));
const FindExpert = Loadable(lazy(() => import("views/findExpert")));
const AboutExpert = Loadable(lazy(() => import("views/aboutExpert")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/find-expert",
      element: <FindExpert />,
    },
    {
      path: "/about-expert",
      element: <AboutExpert />,
    },
  ],
};

export default MainRoutes;
