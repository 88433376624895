import { Button, useTheme } from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";
import React from "react";

const LightButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <AnimateButton>
        <Button
          sx={{
            background: theme.palette.background.paper,
            boxShadow: "0px 10px 40px 0px rgba(85, 85, 85, 0.12)",

            ":hover": {
              background: theme.palette.background.paper,
              boxShadow: "none",
            },
          }}
          type="button"
          variant="contained"
          {...props}
        >
          {props.title}
        </Button>
      </AnimateButton>
    </>
  );
};

export default LightButton;
