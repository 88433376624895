import { Button, useTheme } from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";

const DarkButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <AnimateButton>
        <Button
          sx={{
            background: theme.palette.custom.darkColor,
            color: `${theme.palette.common.white} !important`,

            ":hover": {
              background: theme.palette.custom.darkColor,
              boxShadow: "none",
            },
          }}
          type="button"
          variant="contained"
          {...props}
        >
          {props.title}
        </Button>
      </AnimateButton>
    </>
  );
};

export default DarkButton;
